<template>
  <div>
    <div class="air__utils__heading">
      <h5>Reportes</h5>
    </div>
    <my-breadcrumbs :routes="[
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Reportes',
          aIcon: '',
        },
      ]"/>
    <!--    Enlaces-->
    <div class="row">
      <div class="col-lg-2 col-md-6 col-sm-12 offset-lg-1 offset-md-0 offset-sm-0">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'ServicePlanningReport'}">
                <strong><b-icon icon="calendar3"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'ServicePlanningReport'}">
              <h6 class="text-block text-secondary">Planeación de Servicios</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'LogisticReportMenu'}">
                <strong> <b-icon icon="calendar2-week"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'LogisticReportMenu'}">
              <h6 class="text-block text-secondary">Logística</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: ''}">
                <strong> <b-icon icon="calculator-fill"/></strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: ''}">
              <h6 class="text-block text-secondary">Laboratorios</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'operation_reports'}">
                <strong> <b-icon icon="truck-flatbed"/> </strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'operation_reports'}">
              <h6 class="text-block text-secondary">Operaciones</h6>
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-sm-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: ''}">
                <strong> <b-icon icon="folder-fill"/> </strong>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: ''}">
              <h6 class="text-block text-secondary">Administración</h6>
            </router-link>
          </div>
        </div>
      </div>

    </div>
    <!--    Enlaces-->
  </div>
</template>

<script>
export default {
  name: 'laboratoryMenu',
}
</script>

<style scoped></style>
